<template>
  <div class="home">
    <Header />
    <b-container>
      <b-row align-v="center" id="box">
        <b-col></b-col>
        <b-col cols="8">
          <b-card class="text-start">
            <b-input-group class="mt-3">
              <template #append>
                <b-input-group-text
                  ><b-icon icon="search" @click="goToViewDocument"></b-icon
                ></b-input-group-text>
              </template>
              <b-form-input type="search" @keyup.enter="goToViewDocument" v-model="document"></b-form-input>
            </b-input-group>
            <div class="dropdown-divider"></div>

            <b-row>
              <b-col md="6" offset-md="3">
                <b-button
                  block
                  variant="primary"
                  class="btn-list"
                  @click="goToList"
                  >Carregar lista completa</b-button
                >
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import { v4 as uuidv4 } from 'uuid';
export default {
  name: "Home",
  components: {
    Header,
  },
  data() {
    return {
      document: "",
      modeProfile: "visualization",
      dataSearch: [],
    };
  },
  created: async function () {
    console.log(this.$root.pessoaId);
  },
  methods: {
    goToList() {
      this.$router.push("/list");
    },
    goToViewDocument() {   
      if (this.document !== "") {
        const body = {
          'requestId': uuidv4(),
          'document' : this.document
        };
        return this.axios.post("/restrictivelist/find", body)
          .then( resposta => {
            var result = resposta.data;
            if (result.status && result.json)
            {
              this.$root.rootObject = JSON.parse(result.json);
              console.log(body)
              this.$router.push({ name: "ViewDocument", params: { document: this.document, modeProfile: this.modeProfile, }});
            }
            else 
            {
              var message = "";
              if(result.json === ""){
                message = "O documento não foi localizado";
              }
              else{
                message = result.json
              }
              this.$root.$emit('notify', { label: message, time: 10, type: 'danger'});
              return;
            }
          })
          .catch( error => {
            this.$root.$emit('notify', { label: 'ERROR method name: goToViewDocument => '+error, time: 10, type: 'danger'});
          });
      }
      else{
        this.$root.$emit('notify', { label: 'É preciso informar um documento', time: 5, type: 'danger'});
      }
    }
  },
  mounted() {
    this.$root.pessoaId = sessionStorage.pessoaId;
  },
};
</script>

<style scoped>
#box {
  margin-top: 50px;
}

.action {
  width: 90%;
}

.btn-list {
  font-variant: all-small-caps;
}

.dropdown-divider {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 10px;
}

.dropdown-divider::after {
  content: "OU";
  color: #000;
  width: 33px;
  height: 10px;
  position: absolute;
  top: 96px;
  background: #fff;
  font-size: 13px;
  left: 46%;
  text-align: center;
}
</style>